import React, { useState, useEffect } from 'react';
import {
  DndContext,
  DragEndEvent,
  useSensor,
  useSensors,
  MouseSensor,
  TouchSensor,
} from '@dnd-kit/core';
import {
  SortableContext,
  rectSortingStrategy,
  arrayMove,
} from '@dnd-kit/sortable';
import SortableCard from './SortableCard';
import AddCard from './AddCard';
import './styles.css';

interface CardItem {
  id: string;     // Must be unique
  word: string;
  meaning: string;
}

const FUNCTION_KEY = process.env.REACT_APP_AZURE_FUNCTION_KEY;
const FUNCTION_BASE_URL = process.env.REACT_APP_AZURE_FUNCTION_BASE_URL;

const CardPool: React.FC = () => {
  const [cards, setCards] = useState<CardItem[]>([]);

  const url = ``;

  // (Optional) Activation constraints so quick clicks won't trigger drag
  const mouseSensor = useSensor(MouseSensor, { activationConstraint: { distance: 5 } });
  const touchSensor = useSensor(TouchSensor, {
    activationConstraint: {
      delay: 200,
      tolerance: 5,
    },
  });
  const sensors = useSensors(mouseSensor, touchSensor);

  // Fetch cards from the server on component mount
  useEffect(() => {
    fetchAllCards();
  }, []);

  function fetchAllCards() {
    fetch(`${FUNCTION_BASE_URL}/api/han/word-cards?code=${FUNCTION_KEY}`)
      .then((res) => res.json())
      .then((data: CardItem[]) => {
        console.log('Fetched cards:', data);
        setCards(data);
      })
      .catch((err) => {
        console.error('Error fetching cards:', err);
      });
  }

  // Called after a drag is finished
  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;
    if (!over || active.id === over.id) {
      return;
    }
    const oldIndex = cards.findIndex((item) => item.id === active.id);
    const newIndex = cards.findIndex((item) => item.id === over.id);
    // Reorder array in local state
    const newArray = arrayMove(cards, oldIndex, newIndex);
    setCards(newArray);

    // If you want to persist the new order in the DB, you'd have an API for that:
    // fetch('http://localhost:7071/api/reorderCards', { method: 'POST', body: JSON.stringify(newArray) })
  }

  // Create a new card by calling the backend, then refresh
  function handleCreateCard(word: string, meaning: string) {
    fetch(`${FUNCTION_BASE_URL}/api/han/word-cards?code=${FUNCTION_KEY}`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ word, meaning }),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error('Failed to create card');
        }
        return res.json();
      })
      .then((createdCard: CardItem) => {
        // Option 1: Re-fetch the entire list to get new data
        // fetchAllCards();

        // Option 2: Just append the newly created card to local state
        setCards((prev) => [...prev, createdCard]);
      })
      .catch((err) => {
        console.error('Error creating new card:', err);
      });
  }

  return (
    <div>
      <h2>Words</h2>
      {/* A simple form to add new cards */}
      <AddCard onCreate={handleCreateCard} />

      <DndContext sensors={sensors} onDragEnd={handleDragEnd}>
        <SortableContext items={cards.map((c) => c.id)} strategy={rectSortingStrategy}>
          <div className="card-pool-container">
            {cards.map((card) => (
              <SortableCard key={card.id} id={card.id} word={card.word} meaning={card.meaning} />
            ))}
          </div>
        </SortableContext>
      </DndContext>
    </div>
  );
};

export default CardPool;