import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import WordCard from './WordCard';

interface SortableCardProps {
  id: string;      // Must match the ID used in <SortableContext>
  word: string;
  meaning: string;
}

const SortableCard: React.FC<SortableCardProps> = ({ id, word, meaning }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id });

  const style: React.CSSProperties = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : undefined,
    // Because each card is inline-block, we generally keep no forced width.
    // You can set a fixed min-width or something if desired.
    display: 'inline-block',
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <WordCard word={word} meaning={meaning} />
    </div>
  );
};

export default SortableCard;