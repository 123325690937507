import React, { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import axios from 'axios';
import 'react-quill/dist/quill.snow.css';
import CardPool from './CardPool';


const FUNCTION_KEY = process.env.REACT_APP_AZURE_FUNCTION_KEY;
const FUNCTION_BASE_URL = process.env.REACT_APP_AZURE_FUNCTION_BASE_URL;

const WordsNoteBook: React.FC = () => {
    const [content, setContent] = useState('');
    const [noteName, setNoteName] = useState('Words');
    const [isSaving, setIsSaving] = useState(false);

    const username = 'customuser';
    const password = 'custompassword';
    const token = btoa(`${username}:${password}`);
    axios.defaults.headers.common['Authorization'] = `Basic ${token}`;
    axios.defaults.headers.common['ngrok-skip-browser-warning'] = 'true';
    const url = `${FUNCTION_BASE_URL}/api/han/notes/name/${noteName}?code=${FUNCTION_KEY}`;

    useEffect(() => {

        // Fetch the initial content of the note when the component mounts
        // axios.get(`https://4e0a-2a02-4780-b-1270-0-21f9-9488-2.ngrok-free.app/han/notes/name/${noteName}`)
        axios.get(url)

            .then(response => {
                setContent(response.data.content);
            })
            .catch(error => {
                console.error('Error fetching the note:', error);
            });
    }, [noteName]);

    const handleChange = (value: string) => {
        setContent(value);
    };

    const saveNote = () => {
        setIsSaving(true);

        const noteData = {
            name: noteName,
            content: content
        };

        // axios.post(`https://4e0a-2a02-4780-b-1270-0-21f9-9488-2.ngrok-free.app/han/notes/name/${noteName}`, noteData)
        axios.post(url, noteData)

            .then(response => {
                console.log('Note saved successfully:', response.data);
            })
            .catch(error => {
                console.error('Error saving the note:', error);
            })
            .finally(() => {
                setIsSaving(false);
            });
    };

    // const backupNote = () => {
    //     setIsSaving(true);

    //     const noteData = {
    //         name: noteName,
    //         content: content
    //     };

    //     // axios.post(`https://4e0a-2a02-4780-b-1270-0-21f9-9488-2.ngrok-free.app/han/notes/name/${noteName}`, noteData)
    //     axios.post(`http://localhost:9202/han/notes/backup/name/${noteName}`, noteData)

    //         .then(response => {
    //             console.log('Note backed up successfully:', response.data);
    //         })
    //         .catch(error => {
    //             console.error('Error backing up the note:', error);
    //         })
    //         .finally(() => {
    //             setIsSaving(false);
    //         });
    // };

    const modules = {
        toolbar: [
            [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
            [{ size: [] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            ['link', 'image', 'video'],
            [{ 'color': [] }, { 'background': [] }], // Text color and background color (for highlighting)
            ['clean']
        ],
    };

    const formats = [
        'header', 'font', 'size',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet',
        'link', 'image', 'video',
        'color', 'background' // Enable text color and background color formats
    ];

    return <>
        {/* <div>
            <h2>Words</h2>
            <button onClick={saveNote}>Save</button>
            {isSaving && <p>Saving...</p>} Display a saving indicator
        </div> */}
        <CardPool />
        {/* <ReactQuill
            value={content}
            onChange={handleChange}
            modules={modules}
            formats={formats}
        /> */}
    </>;
}


export default WordsNoteBook;