import React, { useState, useRef, useEffect } from 'react';
import './styles.css';

interface WordCardProps {
  word: string;
  meaning: string;
}

const WordCard: React.FC<WordCardProps> = ({ word, meaning }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const modalRef = useRef<HTMLDivElement>(null);

  // Close modal if user clicks outside the modal content
  useEffect(() => {
    function handleClickOutside(e: MouseEvent) {
      if (modalRef.current && !modalRef.current.contains(e.target as Node)) {
        setIsModalOpen(false);
      }
    }
    if (isModalOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [isModalOpen]);

  return (
    <>
      <div className="word-card" onClick={() => setIsModalOpen(true)}>
        {word}
      </div>

      {isModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content" ref={modalRef}>
            <h2>{word}</h2>
            <p>{meaning}</p>
          </div>
        </div>
      )}
    </>
  );
};

export default WordCard;