import React, { useState } from 'react';

interface AddCardProps {
  onCreate: (word: string, meaning: string) => void;
}

const AddCard: React.FC<AddCardProps> = ({ onCreate }) => {
  const [word, setWord] = useState('');
  const [meaning, setMeaning] = useState('');

  function handleSubmit(e: React.FormEvent) {
    e.preventDefault();
    // Basic validation
    if (!word.trim() || !meaning.trim()) {
      alert('Please provide both word and meaning.');
      return;
    }
    onCreate(word, meaning);
    setWord('');     // clear inputs
    setMeaning('');
  }

  return (
    <form onSubmit={handleSubmit} style={{ marginBottom: '1rem' }}>
      <div>
        <label>Word: </label>
        <input
          type="text"
          value={word}
          onChange={(e) => setWord(e.target.value)}
          placeholder="Enter the word"
        />
      </div>
      <div>
        <label>Meaning: </label>
        <input
          type="text"
          value={meaning}
          onChange={(e) => setMeaning(e.target.value)}
          placeholder="Enter the meaning"
        />
      </div>
      <button type="submit">Add Card</button>
    </form>
  );
};

export default AddCard;